.Login_OTP_container {
	width: 65%;
	margin: 30px auto;
	box-shadow: 3px 4px 13px lightgrey;
	text-align: center;
	padding: 2em;
	border-radius: 20px;
	font-family: "Poppins", sans-serif !important;
}

.lockicon {
	width: 90px;
}

.otpText {
	margin-top: 3em;
	width: 60%;
	margin-left: 20%;
}

.otpText input[type="text"] {
	text-align: center !important;
}

.resend {
	margin-top: 2em;
}

.resend>div {
	cursor: pointer;
}

.SuccessText {
	font-family: "Poppins", sans-serif;
	font-size: 15px;
	color: #55a055;
	margin-top: 10px;
	margin-bottom: -10px;
	font-weight: 700;
}

.Login_OTP_btn_box {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 65%;
	margin: 0 auto;
}

@media only screen and (max-width: 400px) {
	.Login_OTP_btn_box {
		width: 80%;
		margin: 0 auto;
	}
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
	.Login_OTP_container {
		padding: 1em;
		width: 78%;
	}
	.Otp_request_loginMode {
		padding: 1em;
	}
}

@media only screen and (max-width: 468px) {
	.Login_OTP_container {
		width: 78%;
		padding: 1em !important;
	}
	.Otp_request_loginMode {
		width: 90%;
		padding: 1em !important;
	}
}