.retrival_container {
	width: 75%;
	margin: 30px auto;
	box-shadow: 3px 4px 13px lightgrey;
	padding: 2em;
	border-radius: 20px;
}

.retrivaldobdropdown {
	width: 100%;
	margin-right: 10px;
	display: flex;
	justify-content: space-between;
}

#retrivalmonthdropdown,
#retrivaldaydropdown,
#retrivalyeardropdown {
	font-size: 16px;
	font-family: "Poppins", sans-serif;
	cursor: pointer;
	padding: 0px 0px 15px 0px;
	width: 30%;
	border: none;
	border-bottom: 1px solid #d3d7d8;
	color: gray;
	background-color: #fff !important;
}

.retrival_btn_box {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 65%;
	margin: 0 auto;
}

.retriva_dob_dropdown {
	min-width: 150px !important;
	margin-top: -10px;
	margin-right: 10px !important;
}

@media only screen and (max-width: 400px) {
	.retrival_btn_box {
		width: 80%;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 468px) {
	.retrival_container {
		width: 80%;
		padding: 1em !important;
	}
}

@media only screen and (max-width: 600px) {
	.retrivaldobdropdown {
		width: 100%;
		height: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}
	#retrivalmonthdropdown,
	#retrivaldaydropdown,
	#retrivalyeardropdown {
		width: 100% !important;
		background-color: #fff !important;
	}
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
	.retrival_container {
		padding: 1em;
	}
}