.Login_mode_container {
	width: 65%;
	margin: 4em auto;
	box-shadow: 3px 4px 13px lightgrey;
	text-align: center;
	padding: 4em;
	border-radius: 20px;
}

.email_form_control>.MuiButtonBase-root.Mui-checked {
	color: #00afb9 !important
}

.mobile_form_control>.MuiButtonBase-root.Mui-checked {
	color: #00afb9 !important
}

.Login_mode_btn_box {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 65%;
	margin: 0 auto;
}

.mobile_form_control {
	margin-left: 50px !important;
}

@media only screen and (max-width: 468px) {
	.Login_mode_container {		
		width: 80%;
		padding: 1em;
	}
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
	.Login_mode_container {		
		width: 80%;
		padding: 1em;
	}
	.LoginMethod {
		display: flex !important;
	}
}

@media only screen and (max-width: 478px) {
	.email_form_control,
	.mobile_form_control {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.mobile_form_control {
		margin-left: 0px !important;
	}
	.email_form_control>.MuiTypography-body1,
	.mobile_form_control>.MuiTypography-body1 {
		font-size: 12px !important;
	}
}