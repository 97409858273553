.footer {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    height: 55px;
    text-align: center;
    align-items: center;
  }

  .footer_content {
    display: flex;
    color: #cecece;
    align-items: center;
  }
  
  .policy{
    padding-right: 25px;
  }
  
  .footer_logo {
    height: 55px;
    vertical-align: middle;
  }
  
  .footer-rights {
    color: #cecece;
  }

  .policy a {
    color: #cecece !important;
  }

  .policy a:hover {
    color: #cecece !important;
  }

  @media only screen and (max-width: 600px) {
    .footer {
      display: block !important;
    }
    .footer-rights {
      display: block;
    }
    .footer_content {
      margin-top: 20px;
      display: block;
    }
    .policy {
      padding-top: 5px;
      padding-right: 0px !important;
    }
  }
  