/* Variables */

:root {
	--gray-offset: rgba(0, 0, 0, 0.03);
	--gray-border: rgba(5, 5, 5, 0.15);
	--gray-light: rgba(0, 0, 0, 0.4);
	--gray-mid: rgba(0, 0, 0, 0.7);
	--gray-dark: rgba(0, 0, 0, 0.9);
	--body-color: var(--gray-mid);
	--headline-color: var(--gray-dark);
	--accent-color: #0066f0;
	--body-font-family: "Poppins", sans-serif;
	--radius: 6px;
	--form-width: 343px;
}


/* Layout */

.sr-field-error {
	color: var(--accent-color);
	text-align: left;
	font-size: 13px;
	line-height: 17px;
	margin-top: 12px;
}

.sr-field-success {
	text-align: left;
	font-size: 13px;
	line-height: 17px;
	margin-top: 5px;
	padding: 5px;
	color: #fff !important;
}


/* Form */

.sr-form-row {
	margin: 16px 0;
}


/* label {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 8px;
    display: inline-block;
  } */


/* Inputs */

.sr-input {
	border: 1px solid var(--gray-border);
	border-radius: var(--radius);
	padding: 5px 12px;
	height: 35px;
	width: 100%;
	transition: box-shadow 0.2s ease;
	background: white;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	color: gray;
}

.sr-input:focus,
.StripeElement--focus,
.focused {
	box-shadow: 0 0 0 1px rgba(0, 175, 185, 1), 0 1px 1px 0 rgba(0, 175, 185, 0.07), 0 0 0 1px rgba(0, 175, 185, 1);
	outline: none;
	z-index: 9;
}

.sr-input::placeholder,
input[type="text"]::placeholder {
	color: var(--gray-light);
}

.sr-combo-inputs>.sr-combo-inputs-row:first-child input:only-child {
	border-radius: var(--radius) var(--radius) 0 0;
}

.sr-combo-inputs-row {
	width: 100%;
	display: flex;
}

.sr-combo-inputs-row:not(:first-of-type) .sr-input {
	border-radius: 0 0 var(--radius) var(--radius);
}

/* Code block */

.sr-callout {
	background: var(--gray-offset);
	padding: 12px;
	border-radius: var(--radius);
	max-height: 200px;
	overflow: auto;
}

@-webkit-keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* Animated form */
.sr-payment-form .sr-form-row {
	animation: 0.4s field-in;
	animation-fill-mode: both;
	animation-timing-function: ease;
	transform-origin: 50% 0%;
}

.hidden {
	display: none;
}

@keyframes field-in {
	0% {
		opacity: 0;
		transform: translateY(8px) scale(0.95);
	}
	100% {
		opacity: 1;
		transform: translateY(0px) scale(1);
	}
}

@keyframes form-in {
	0% {
		opacity: 0;
		transform: scale(0.98);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}


/* Responsiveness */
@media (max-width: 720px) {
	.sr-payment-summary {
		text-align: center;
	}
}


/*=====test====== */

.QR_code_image{
	width: 180px;
}

.mobile-phone {
	border-radius: 25px;
	background-color: white;
	box-shadow: 0 12px 20px rgba(0, 0, 0, 0.30), 0 10px 10px rgba(0, 0, 0, 0.22);
	height: 470px;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.download-btn{
	width: 130px;
	background-color: #f07167 !important;
	color: #fff !important;
	outline: none;
	border: 0;
	display: flex;
	align-items: center;
	font-weight: bold;
	line-height: 1.3;
	font-size: 14px;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: 0.465rem 1rem;
	border-radius: 0.9rem;
	font-family: var(--fontFamily) !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.title_color {
	color: #f07167 !important;
	font-weight: bold !important;
	font-size: 15px !important;
}

.title_value {
	padding-left: 5px;
	color: #888 !important;
	font-weight: bold;
	font-size: 15px !important;
}
.note_text{
	padding-top: 6px;
	margin:0 20px;	
	color: #888 !important;
	font-weight: bold;
	font-size: 13px !important;
}

.paymentFailedStatus {
	padding-top: 5px !important;
	color: #f07167 !important;
	font-weight: bold;
	font-size: 15px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.paymentSuccessStatus {
	padding-top: 5px !important;
	color: green !important;
	font-weight: bold;
	font-size: 15px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-width:1390px) {
	.title_color , .title_value {
		font-size: 16px !important;
	}

	
}

@media only screen and (max-width:1024px) {
	.QR_code_image{
		width: 160px;
	}
}