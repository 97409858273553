
.wallet_container {
    width: 80%;
    margin:0 auto;
    display: flex !important;
    justify-content: flex-end;
  }


  /* PDF css */

.walletbtn {
    margin-top: 4px;
    background: #4bd3c5;
    border: none;
    padding: 5px;
    width: 10vw;
    border-radius: 10px;
    color: white;
    margin-right: 20px;
    font-size: 16px;
  }
  .walletbtn:focus {
    padding: 4px;
    border: 1px solid #349389;
  }
  
  
  .pdfbody{
    width: 80% !important;
    margin:0 auto;
  }


  .btnbox {
    display: flex;
    justify-content: space-evenly;
  }
  
  .savebtn {
    background: #4bd3c5;
    border: none;
    padding: 10px;
    width: 20%;
    border-radius: 10px;
    color: white;
    font-size: 18px;
  }
  .pdfbtn {
    margin-top: 40px;
    background: #4bd3c5;
    border: none;
    padding: 15px;
    width: 15vw;
    border-radius: 10px;
    color: white;
    font-size: 18px;
  }
  .nextbtnDiv {
    text-align: center;
    margin-top: 50px;
  }
  

  



  

  @media only screen and (max-width: 600px) {
 
    .wallet_container {
      display: flex;
      flex-direction: column;
    }

}

@media only screen and (min-width: 401px) and (max-width: 600px) {
    
    .viewer-button {
      padding: 0px !important;
    }
    .viewer-toolbar-left {
      font-size: 10px !important;
    }
    .viewer-toolbar-right {
      font-size: 10px !important;
    }
    .viewer-toolbar-center {
      font-size: 10px !important;
    }
    .viewer-toolbar-current-page-input {
      width: 30px !important;
    }
    .pdfbtn {
      width: 23vw !important;
    }
    
  }
  
  




@media only screen and (max-width: 769px) {
    .wallet_container {
      margin-top: 60px;
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      margin-left: 40px;
    }
    .walletbtn {
      width: 35vw;
    }
    .Reportbtn
  {
    width: 50vw;
  }

}


@media only screen and (max-width: 376px) {

    .wallet {
      margin-left: 20px;
    }
  
  .pdfbody{
      width: 90% !important;
    }
    .Reportbtn
    {
      width: 60vw;
    }
    .walletbtn.walltetbtn1 {
      width: 45vw !important;
    }
    .googleimg{
      width:250px;
    }
  
  }
  @media only screen and (min-width: 769px) and (max-width: 1400px) {
    .walletbtn {
      width: 14vw;
    }
  }

  @media only screen and (max-width: 769px) {
  

    .viewer-layout-container,
    .viewer-layout-toolbar {
      overflow-x: auto;
    }
    .btnbox {
      width: 80%;
      margin:0 auto;
      justify-content: center !important;
    }
    .pdfbtn1 {
      width: 25vw !important;
    }
  
    .pdfbody{
      height: 600px;
      margin:0  auto !important;
    }
  }